import React from "react"
import {Header, Icon, Item} from "semantic-ui-react";
import RaisedSegment from "./RaisedSegment";
import styles from "./newslistelement.module.css";
import FriendlyButton from "./buttons/FriendlyButton";

const NewsListElement = ({post}) => (
    <RaisedSegment>
        <Header as={'h4'} className={styles.header}>
            {post.frontmatter.title}
        </Header>

        <Item.Group className={styles.items}>
            <Item className={styles.item}>
                {
                    !!post.frontmatter.image &&
                    <Item.Image className={styles.image} src={post.frontmatter.image.childImageSharp.fluid.src}/>
                }
                <Item.Content className={styles.itemContent}>
                    {
                        !!post.frontmatter.happening_date &&
                        <span className={styles.date}>{post.frontmatter.happening_date}</span>
                    }
                    {
                        !!post.frontmatter.place &&
                        <span className={styles.place}>{post.frontmatter.place}</span>
                    }
                    <p className={styles.summary}>{post.frontmatter.summary}</p>
                </Item.Content>
            </Item>
        </Item.Group>
        {
            !!post.frontmatter.path &&
            <div className={styles.button}>
                <FriendlyButton link={post.frontmatter.path}>
                    Mehr erfahren
                </FriendlyButton>
            </div>
        }

        {
            !!post.frontmatter.external &&
            <div className={styles.button}>
                <FriendlyButton link={post.frontmatter.external}>
                    <Icon name='external alternate'/> Externe Seite aufrufen
                </FriendlyButton>
            </div>
        }
        {
            !!post.frontmatter.file &&
            <div className={styles.button}>
                <FriendlyButton link={post.frontmatter.file.publicURL}>
                    <Icon name='file'/> Dokument anschauen
                </FriendlyButton>
            </div>
        }

    </RaisedSegment>
);

export default NewsListElement;
