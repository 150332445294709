import React from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import NewsListElement from "../components/utils/NewsListElement";
import {graphql} from "gatsby";
import PageHeader from "../components/utils/PageHeader";
import {Container} from "semantic-ui-react";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import layout from "../templates/layout.module.css";

const News = ({
                  data: {
                      allMarkdownRemark: {edges},
                  },
              }) => {
    const Posts = edges
        .filter(edge =>  !!edge.node.frontmatter.path || !!edge.node.frontmatter.external || !!edge.node.frontmatter.file)
        .map(edge => <NewsListElement key={edge.node.id} post={edge.node}/>);

    return <LayoutContainer>
        <SEO title={"News"}/>
        <MobileTabletContainer>
            <MainNavigation/>
            <Container>
                <PageHeader title={'News'}/>
                    {Posts}
            </Container>
            <Footer/>
        </MobileTabletContainer>
        <DesktopContainer>
            <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container>
                <PageHeader title={'News'}/>
                <div style={{margin: '2rem 2rem 0 2rem', paddingBottom: '2rem'}}>
                    {Posts}
                </div>
            </Container>
            <Footer/>
            </div>
        </DesktopContainer>
    </LayoutContainer>
};

export default News;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
    filter:{
        frontmatter: {type: { eq: "news-article" }}
      }
    sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            type
            date(formatString: "DD.MM.YYYY")
            happening_date(formatString: "DD.MM.YYYY")
            summary
            external
            place
            image {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_noBase64
                      src
                    }
                  }
                }
            file {
                id
                publicURL
            }
          }
        }
      }
    }
  }
`;